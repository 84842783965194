.back{
  border: 1px solid #0091D2;
  filter: drop-shadow(0px 4px 10px rgba(25, 119, 204, 0.2));
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 48px;
  text-decoration: none;
  div{
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #0091D2;
  }
}

.bgBlue{
  background: #0091D2;
  box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
  border-radius: 3px;
  div{
    color: #ffffff;
    margin-left: 0;
  }
}


@media screen and (max-width: 991px){
  .back{
    border: none;
    justify-content: flex-start;
  }
  .bgBlue{
    justify-content: center;
  }
}