.faq{
  padding: 66px 0 37px 0;
  &__title{
    margin: 0;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
    width: calc(50% - 10px);
    color: #0091D2;
  }
  &__wrapper{
    margin-top: 66px;
    display: flex;
    justify-content: space-between;
  }
  &__left,
  &__right{
    width: calc(50% - 10px);
    > div {
      margin-bottom: 20px;
    }
  }
  &__left{
    > div{
      &:nth-child(2){
        height: 91.59px;
      }
    }
  }
  &__right{
    > div{
      &:nth-child(6){
        height: 91.59px;
      }
    }
  }
}


@media screen and (max-width: 991px){
  .faq{
    &__title{
      width: 100%;
    }
    &__left{
      > div{
        &:nth-child(2){
          height: 82px;
        }
      }
    }
    &__right{
      > div{
        &:nth-child(6){
          height: 82px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .faq{
    padding-top: 32px;
    &__title{
      font-size: 28px;
    }
    &__wrapper{
      margin-top: 12px;
      flex-direction: column;
    }
    &__left,
    &__right{
      width: 100%;
    }
    &__left{
      > div{
        &:nth-child(2){
          height: auto;
        }
      }
    }
    &__right{
      > div{
        &:nth-child(6){
          height: auto;
        }
      }
    }
  }
}