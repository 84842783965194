.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 285px);
  min-height: 550px;
  &__title{
    margin-top: 24px;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 991px){
  .page{
    height: calc(100vh - 420px);
    min-height: 600px;
  }
}

@media screen and (max-width: 768px){
  .page{
    height: calc(100vh - 752px);
    min-height: 600px;
    img{
      width: 300px;
    }
    &__title{
      font-size: 32px;
    }
  }
}

@media screen and (max-width: 576px){
  .page{
    img{
      width: 90%;
      max-width: 302px;
    }
    &__title{
      padding: 0 10px;
    }
    a{
      width: calc(100% - 20px);
      margin-left: auto;
      margin-right: auto;
    }
  }
}