.login{
  height: calc(100vh - 285px);
  min-height: 556px;
  padding: 86px 0;
  overflow: hidden;
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__left,
  &__right{
    width: calc(50% - 32px);
  }
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin: 0;
  }
  &__descr{
    display: flex;
    align-items: center;
    div{
      margin-right: 10px;
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #25283C;
    }
    a{
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      text-decoration-line: underline;
      color: #0091D2;
      text-decoration: underline;
    }
  }
  &__form{
    margin-top: 24px;
  }
  &__label{
    margin: 0 0 10px 0;
    display: block;
    width: 100%;
    position: relative;
    input{
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      padding: 0 60px 0 16px;
      color: #2E2E2E;
      height: 53px;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #AEAEAE;
      border-radius: 10px;
      outline: none;
      ::-webkit-input-placeholder {
        color: #AEAEAE;
      }
      ::-moz-placeholder { 
        color: #AEAEAE;
      }
      :-ms-input-placeholder { 
        color: #AEAEAE;
      }
      :-moz-placeholder { 
        color: #AEAEAE;
      }
    }
    button{
      top: 42px;
    }
  }
  &__button{
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 115px;
    height: 43px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    border: 1px solid #0091D2;
    transition: .2s ease-in-out;
    &_disabled{
      opacity: .5;
      pointer-events: none;
      transition: .2s ease-in-out;
    }
  }
  &__right{
    position: relative;
    height: 315px;
    &::after{
      content: '';
      width: 100vw;
      height: 100%;
      background: #E5EEF8 url('../../img/login.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 59px 0;
      border-radius: 20px 0px 0px 20px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__btns{
    margin-top: 24px;
  }
  &__reset{
    padding: 12px 32px;
    margin-left: 24px;
    border: 2px solid #0091D2;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    font-size: 13px;
    line-height: 130%;
    text-align: center;
    color: #0091D2;
    transition: .2s ease-in-out;
    &:hover{
      background: #0091D2;
      color: #ffffff;
      transition: .2s ease-in-out;
    }
  }
  &__btns{
    display: flex;
  }
  &__error{
    font-size: 14px;
    margin-top: 12px;
    color: red;
    text-transform: capitalize;
  }
}

@media screen and (max-width: 991px){
  .login{
    height: calc(100vh - 420px);
    &__title{
      font-size: 32px;
    }
    &__descr{
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;
      div{
        margin: 0;
      }
      a{
        margin-top: 10px;
      }
    }
  }
}


@media screen and (max-width: 768px){
  .login{
    padding: 30px 0 80px 0;
    height: auto;
    &__wrapper{
      flex-direction: column-reverse;
    }
    &__right{
      width: 100%;
      &::after{
        width: 100%;
        border-radius: 20px;
        background-size: contain;
        background-position: center;
      }
    }
    &__left{
      width: 100%;
      padding-top: 15px;
    }
    &__title{
      text-align: center;
    }
    &__descr{
      align-items: center;
    }
    &__button{
      width: 100%;
    }
    &__btns{
      flex-direction: column;
      a{
        margin: 24px 0 0 0;
      }
    }
  }
}

@media screen and (max-width: 576px){
  .login{
    &__right{
      &::after{
        background-size: cover;
      }
    }
  }
}