.modal{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(229, 238, 248, 0.3);
  backdrop-filter: blur(4.5px);
  z-index: 5;
  padding-top: 148px;
  padding-left: 212px;
  &__inner{
    width: 487px;
    padding: 40px 33px;
    background: #ffffff;
    border-radius: 10px;
  }
  &__close{
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    padding: 0;
    margin: -14px -14px 0 auto;
    display: flex;
  }
  &__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: #0091D2;
    text-align: center;
  }
  &__form{
    margin-top: 32px;
    label{
      margin-bottom: 24px;
      display: block;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &__button{
    height: 43px;
    padding: 0 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 8px auto 0 auto;
    border: 1px solid #0091D2;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    transition: .2s ease-in-out;
    &:hover{
      background: #FFFFFF;
      color: #0091D2;
      transition: .2s ease-in-out;
    }
    &_disabled{
      opacity: 0.5;
      pointer-events: none;
      transition: .2s ease-in-out;
    }
  }
}

@media screen and (max-width: 1200px){
  .modal{
    padding: 30px 0 0 60px;
  }
}

@media screen and (max-width: 991px){
  .modal{
    padding: 30px 0 0 0;
    &__inner{
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 768px){
  .modal{
    &__title{
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 576px){
  .modal{
    &__inner{
      width: calc(100% - 40px);
      padding: 40px 17px;
    }
    &__close{
      margin-right: 0;
    }
  }
}