.date{
  position: relative;
}

.span{
  display: block;
  padding-left: 16px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #2E2E2E;
}

.datePicker{
  width: 100%;
  height: 53px;
  border: 1px solid #AEAEAE;
  border-radius: 10px;
  padding: 0 16px;
  outline: none;
  &::-webkit-input-placeholder { 
    font-size: 16px;
    line-height: 130%;
    color: #AEAEAE;
  }
  &::-moz-placeholder { 
    font-size: 16px;
    line-height: 130%;
    color: #AEAEAE;
  }
  &:-ms-input-placeholder {
    font-size: 16px;
    line-height: 130%;
    color: #AEAEAE;
  }
  &:-moz-placeholder { 
    font-size: 16px;
    line-height: 130%;
    color: #AEAEAE;
  }
}

.calendarIcon{
  position: absolute;
  bottom: -7px;
  right: 16px;
}