.invoices{
  &__table{
    margin-top: 22px;
    max-width: 100%;
    overflow: auto;
  }
  &__header{
    display: flex;
    justify-content: space-between;
    &_title{
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-transform: capitalize;
      color: #1C475C;
    }
  }
  &__row{
    display: flex;
    justify-content: space-between;
    &_data{
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #2E2E2E;
      padding: 12px 5px 12px 0;
      border-bottom: 1px solid #AEAEAE;
    }
  }
  &__empty{
    height: 100px;
    border-radius: 10px;
    border: 1px solid #0091D2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 500;
  }
  &__amount{
    width: 18.4%;
    padding-left: 12px;
    min-width: 118px;
  }
  &__status{
    width: 14.8%;
    min-width: 95px;
  }
  &__number{
    width: 26%;
    min-width: 165px;
  }
  &__date{
    width: 26%;
    min-width: 161px;
  }
  &__action{
    width: 14.8%;
    padding-right: 12px;
    min-width: 130px;
    a{
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #0091D2;
      div{
        margin-left: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #0091D2;
      }
      &:active,
      &:focus{
        text-decoration: none;
        color: #0091D2;
      }
    }
  }
}


@media screen and (max-width: 768px){
  .invoices{  
    &__header{
      display: none;
    }
    &__container{
      border: none;
      padding: 0;
    }
    &__row{
      border-bottom: 1px solid #AEAEAE;
      margin-bottom: 18px;
      display: flex;
      flex-wrap: wrap;
      &_data{
        border: none;
      }
    }
    &__amount{
      width: 50%;
      order: 1;
      padding: 0;
      font-weight: 600;
      margin-bottom: 10px;
      min-width: auto;
    }
    &__status{
      width: 50%;
      display: flex;
      justify-content: flex-end;
      order: 2;
      padding: 0;
      margin-bottom: 10px;
      min-width: auto;
    }
    &__number{
      width: 50%;
      order: 3;
      padding: 0;
      min-width: auto;
    }
    &__date{
      width: 100%;
      order: 5;
      padding: 0 0 16px 0;
      margin-top: 0;
      min-width: auto;
    }
    &__action{
      width: 50%;
      order: 4;
      display: flex;
      justify-content: flex-end;
      padding: 0;
      min-width: auto;
      a{
        width: 41px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #E5EEF8;
        border-radius: 6px;
        div{
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 365px){
  .invoices{
    &__number{
      width: calc(50% - 10px);
    }
    &__action{
      width: calc(50% - 10px);
    }
  }
}