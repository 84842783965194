.profile{
  padding: 38px 21px 30px 15px;
  width: 100%;
  &__full{
    overflow: auto;
    position: relative;
    width: 100%;
  }
  &__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: #0091D2;
    margin: 0;
  }
  &__info{
    margin-top: 11px;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    padding: 22px 26px 42px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_wrapper{
      display: flex;
      width: 100%;
      row-gap: 42px;
    }
    &_right{
      width: 100%;
      margin-left: 40px;
      &_wrapper{
        display: flex;
        align-items: flex-start;
      }
    }
    &_block{
      width: calc(33.3% - 30px);
      min-width: 270px;
      margin-bottom: 24px;
      &_title{
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C475C;
        margin-bottom: 4px;
      }
      &_value{
        display: flex;
        align-items: flex-start;
        position: relative;
        &_text{
          // max-width: 100%;
          // overflow: hidden;
          text-overflow: ellipsis;
        }
        div{
          font-size: 18px;
          line-height: 130%;
          letter-spacing: -0.02em;
          color: #2E2E2E;
        }
        div.profile__info_block_file_accept{
          margin-top: 4px;
          font-size: 10px;
          line-height: 120%;
          color: #2E2E2E;
        }
      }
      &_file{
        width: 158px;
        height: 107px;
        background: #F7F7F7;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        pointer-events: none;
        opacity: .5;
        border: 2px solid #ffffff;
        transition: .2s ease-in-out;
        input{
          opacity: 0;
          position: absolute;
          z-index: -3;
        }
      }
    }
    &_photo{
      display: flex;
      align-items: flex-start;
      label{
        width: 200px;
        height: 200px;
        background: #F7F7F7;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        pointer-events: none;
        opacity: .5;
        border: 2px solid #ffffff;
        transition: .2s ease-in-out;
        input{
          opacity: 0;
          position: absolute;
          z-index: -3;
        }
        div{
          font-weight: 600;
          font-size: 40px;
          line-height: 120%;
          letter-spacing: -0.03em;
          color: #2E2E2E;
        }
      }
      &_accept{
        margin-top: 4px;
        font-size: 10px;
        line-height: 120%;
        opacity: 0.5;
        pointer-events: none;
        &_name{
          display: block;
          max-width: 200px;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }
      }
      &_label{
        &_active{
          border: 2px solid #0091D2!important;
          opacity: 1!important;
          pointer-events: all!important;
          transition: .2s ease-in-out;
        }
      }
    }
    &_bottom{
      margin-top: 83px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      &_right{
        width: 100%;
        &_wrapper{
          display: flex;
        }
      }
    }
  }
  &__input{
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
    width: 100%;
    outline: none;
    border: none;
    border: 2px solid #fff;
    text-overflow: ellipsis;
    transition: .2s ease-in-out;
    &_editable{
      border: 2px solid #0091D2;
      border-radius: 8px;
      transition: .2s ease-in-out;
    }
  }
  &__acceptCompany{
    display: block;
    max-width: 158px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__photoUser{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    object-fit: cover;
    &_upper{
      z-index: 4;
    }
  }
  &__textarrea{
    height: 90px;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
    resize: none;
    outline: none;
    border: 2px solid #fff;
    transition: .2s ease-in-out;
    &_editable{
      border: 2px solid #0091D2;
      border-radius: 8px;
      transition: .2s ease-in-out;
    }
  }
}

.invoices{
  padding: 51px 21px 30px 15px;
  overflow: auto;
  &__container{
    padding: 16px 26px 46px 25px;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    margin-top: 6px;
  }
  &__tabs{
    display: flex;
    &_tab{
      padding: 10px 20px;
      background: #ffffff;
      font-size: 16px;
      line-height: 130%;
      color: #2E2E2E;
      cursor: pointer;
      position: relative;
      transition: background .2s ease-in-out, color .2s ease-in-out;
      &::after{
        content: '';
        width: 100%;
        height: 1px;
        background: #E9E9E9;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: .2s ease-in-out;
      }
      &_active{
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #0091D2;
        background: #E5EEF8;
        transition: background .2s ease-in-out, color .2s ease-in-out;
        &::after{
          background: #0091D2;
          height: 2px;
          transition: .2s ease-in-out;
        }
      }
    }
  }
}

.editButton{
  margin: 2px 0 0 10px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  &:active,
  &:focus{
    outline: none;
  }
}

@media screen and (max-width: 1140px){
  .profile{
    &__info{
      &_block{
        width: 100%;
        &_company{
          width: 270px;
        }
      }
      &_right{
        margin-left: 28px;
        &_wrapper{
          flex-direction: column;
        }
      }
      &_bottom{
        &_right{
          &_wrapper{
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .profile{
    &__title{
      font-size: 28px;
    }
    &__info{
      padding-left: 0;
      padding-right: 0;
      border: none;
      flex-direction: column;
      padding-top: 0;
      margin-top: 0;
      &_wrapper{
        row-gap: 19px;
      }
      &_right{
        margin-left: 0;
      }
      &_bottom{
        margin-top: 0;
      }
      &_photo{
        margin-left: auto;
        margin-top: -45px;
        &_accept{
          width: 96px;
          text-align: right;
        }
        label{
          width: 96px;
          height: 96px;
          input{
            display: none;
          }
        }
        .editButton{
          // display: none;
        }
      }
      &_block{
        &_value{
          border: 1px solid #AEAEAE;
          border-radius: 10px;
          padding: 14px 16px;          
          &_text{
            width: 100%;
          }
          &_logo{
            border: none;
            padding: 0;
            > div{
              width: 100%;
            }
            label{
              width: 100%;
            }
            .editButton{
              display: none;
            }
          }
        }
        &_file{
          &_accept{
            text-align: right;
          }
        }
      }
      &_bottom{
        flex-direction: column;
      }
      &_wrapper{
        flex-direction: column;
      }
    }
  }
}


.border__red{
  border-color: red!important;
}