.thank{
  padding: 99px 0 50px 0;
  height: calc(100vh - 285px);
  min-height: 585px;
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__left{
    width: 55%;
    padding-top: 60px;
  }
  &__right{
    width: 40%;
    img{
      width: 100%;
    }
  }
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin: 0;
  }
  &__descr{
    margin-top: 20px;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: #0091D2;
  }
  &__back{
    display: inline-flex;
    margin-top: 20px;
    padding: 12px 42px;
    text-decoration: none;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
  }
}

@media screen and (max-width: 991px){
  .thank{
    height: calc(100vh - 420px);
    min-height: 485px;
  }
}

@media screen and (max-width: 768px){
  .thank{
    padding-top: 59px;
    height: calc(100vh - 752px);
    min-height: 554px;
    &__wrapper{
      flex-direction: column-reverse;
    }
    &__left{
      width: 100%;
    }
    &__right{
      width: 45%;
      margin: 0 auto;
    }
    &__title{
      font-size: 32px;
      text-align: center;
    }
    &__descr{
      text-align: center;
      font-size: 20px;
    }
    &__back{
      display: flex;
      justify-content: center;
      width: 245px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (max-width: 576px){
  .thank{
    height: calc(100vh - 717px);
    min-height: 550px;
    &__right{
      width: 172px;
    }
    &__left{
      padding-top: 42px;
    }
  }
}