.noty{
  padding: 12px 20px 10px 21px;
  display: flex;
  align-items: flex-start;
  &__title{
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
  }
  &__descr{
    margin-top: 8px;
    font-size: 16px;
    line-height: 130%;
  }
  &__info{
    margin-left: 12px;
  }
}

.red{
  background: rgba(234, 84, 85, 0.25);
  border-left: 4px solid #EA5455;
  .noty__title,
  .noty__descr{
    color: #EA5455;
  }
}

.orange{
  background: #FFF1D7;
  border-left: 4px solid #E47B00;
  .noty__title,
  .noty__descr{
    color: #E47B00;
  }
}