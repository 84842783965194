@mixin reset-outline {
  outline-width: 0px;
  outline-color: transparent;
  &:active,&:focus{
    outline-width: 0px;
    outline-color: transparent;
  }
}


.button{
  @include reset-outline;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: .3s ease-in-out;
  img{
    margin-right: 10px;
  }
  &:hover{
    transition: .3s ease-in-out;
  }
}

.whiteBlue{
  border: 1px solid #ffffff;
  background: #ffffff;
  color: #0091D2;
  &:hover{
    border: 1px solid #0091D2;
    background: #0091D2;
    color: #ffffff;
  }
} 


.blueWhite{
  border: 1px solid #0091D2;
  background: #0091D2;
  color: #ffffff;
  &:hover{
    border: 1px solid #0091D2;
    background: #ffffff;
    color: #0091D2;
  }
}

.whiteDarkBlue{
  border: 1px solid #ffffff;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(75, 70, 92, 0.25);
  border-radius: 3px;
  color: #1C475C;
  font-weight: 500;
  &:hover{
    background: #0091D2;
    color: #ffffff;
  }
}

.blueBorder{
  border: 1px solid #0091D2;
  background: #FFFFFF;
  border-radius: 3px;
  color: #0091D2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

@media screen and (max-width: 991px){
  .blueBorder{
    border-color: transparent;
    justify-content: flex-start;
  }
}