.banner{
  overflow: hidden;
  img{
    width: 257px;
  }
  &__content{
    position: relative;
    padding: 47px 0 46px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: -67px;
      background: #E5EEF8;
      border-radius: 20px 0px 0px 20px;
      width: 200vw;
      height: 100%;
    }
  }
  &__image{
    position: relative;
    z-index: 3;
  }
  &__percent{
    margin: 0 20px 0 50px;
    position: relative;
    z-index: 3;
    &_sub{
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #0091D2;
    }
    &_digit{
      font-size: 100px;
      line-height: 120%;
      letter-spacing: -0.08em;
      color: #0091D2;
    }
  }
  &__descr{
    position: relative;
    z-index: 3;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #2E2E2E;
    max-width: 460px;
    margin-right: 12px;
    span{
      color: #0091D2;
    }
  }
}

@media screen and (max-width: 1200px){
  .banner{
    &__percent{
      margin-left: 0;
      &_digit{
        font-size: 100px;
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 991px){
  .banner{
    &__content{
      flex-wrap: wrap;
    }
    &__descr{
      order: 3;
      max-width: 100%;
      margin-top: 35px;
    }
    &__percent{
      &_digit{
        font-size: 90px;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .banner{
    &__content{
      padding: 27px 0 32px 0;
      &::before{
        left: -200px;
      }
    }
    &__percent{
      font-size: 16px;
      &_digit{
        font-size: 80px;
      }
    }
    &__image{
      width: 250px;
    }
    &__descr{
      font-size: 20px;
      margin: 7px 0 0 0;
    }
  }
}

@media screen and (max-width: 380px){
  .banner{
    &__percent{
      &_digit{
        font-size: 70px;
      }
    }
    &__image{
      width: 165px;
    }
  }
}

@media screen and (max-width: 335px){
  .banner{
    &__percent{
      &_digit{
        font-size: 65px;
      }
    }
    &__image{
      width: 160px;
    }
  }
}