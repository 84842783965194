.advantages{
  padding-bottom: 105px;
  overflow: hidden;
  &__wrapper{
    display: flex;
    justify-content: space-between;
  }
  &__block{
    width: calc(50% - 10px);
    background: #0091D2;
    border: 2px solid #E9E9E9;
    border-radius: 20px;
    padding: 19px 87px 32px 38px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_title{
      font-weight: 600;
      font-size: 36px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #FFFFFF;
      margin-bottom: 42px;
    }
    button{
      margin-top: 22px;
      font-weight: 500;
      &:hover{
        border: 1px solid #fff;
      }
    }
    &:nth-child(even){
      background: #ffffff;
      .advantages__block_title{
        color: #0091D2;
      }
      .advantages__text{
        color: #000000;
      }
      button{
        &:hover{
          border: 1px solid #0091D2;
        }
      }
    }
  }
  &__row{
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    svg{
      margin-top: 3px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  &__text{
    margin-left: 10px;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }
}

@media screen and (max-width: 991px){
  .advantages{
    &__wrapper{
      flex-direction: column;
    }
    &__block{
      width: 100%;
      &:last-child{
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .advantages{
    padding-bottom: 40px;
    &__block{
      &_title{
        font-size: 28px;
      }
    }
    &__text{
      font-size: 16px;
    }
  }
}