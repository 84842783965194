.balance{
  padding: 38px 20px 101px 16px;
  width: 100%;
  &__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: #0091D2;
    margin: 0;
  }
  &__current{
    margin-top: 11px;
    padding: 8px 14px 8px 28px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__balance{
    display: flex;
    align-items: center;
    div{
      margin-left: 13px;
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #1C475C;
    }
  }
  &__stat{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 42px;
    &_count{
      color: #0091D2;
      font-weight: 600;
      font-size: 40px;
      line-height: 120%;
      letter-spacing: -0.03em;
    }
    &_text{
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #0091D2;
    }
  }
  &__info{
    display: flex;
    align-items: center;
  }
  &__check{
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
    background: #E5EEF8;
    border-radius: 10px;
    padding: 18px 33px 27px 27px;
    &_left{
      width: calc(45% - 50px);
    }
    &_right{
      width: calc(55% - 53px);
      &_blur{
        pointer-events: none;
        opacity: 0.5;
        transition: .2s ease-in-out;
      }
    }
    &_label{
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #1C475C;
    }
    &_wrapper{
      margin-top: 13px;
      label{
        margin-bottom: 16px;
      }
    }
    &_check{
      margin-top: 14px;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #0091D2;
      margin-bottom: 14px;
    }
    &_value{
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #2E2E2E;
    }
    &_price{
      font-size: 20px;
      line-height: 130%;
      text-align: right;
      letter-spacing: -0.02em;
      color: #2E2E2E;
    }
    &_summary{
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_title{
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #1C475C;
    }
    &_sum{
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #0091D2;
    }
    &_vat{
      font-size: 10px;
      line-height: 120%;
      color: #929191;
      &_value{
        font-size: 10px;
        line-height: 120%;
        color: #0091D2;        
      }
    }
  }
  &__payment{
    margin-top: 17px;
    label{
      margin-bottom: 8px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &_error{
      margin-top: 14px;
      color: red;
    }
  }
  &__checkout{
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 48px;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    div{
      margin-left: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;
    }
    &_blur{
      pointer-events: none;
      opacity: 0.5;
      transition: .2s ease-in-out;
    }
  }
  &__coupon{
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    label{
      display: block;
      width: 100%;
      & > span{
        padding-left: 0;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 8px;
      } 
      input{
        padding-right: 20px;
      }
    }
    &_button{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      min-width: 108px;
      height: 43px;
      margin-bottom: 5px;
      border: 1px solid #0091D2;
      border-radius: 3px;
      background: #0091D2;
      box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;
      outline: none;
      transition: .2s ease-in-out;
      &:hover{
        color: #0091D2;
        background: #ffffff;
        transition: .2s ease-in-out;
      }
    }
  }
  &__terms{
    margin-top: 34px;
    label{
      &:last-child{
        margin-top: 12px;
      }
    }
  }
}

.invoice{
  &__title{
    margin-top: 31px;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: #0091D2;
  }
}

@media screen and (max-width: 1200px){
  .balance{
    &__check{
      &_left{
        width: calc(45% - 20px);
      }
      &_right{
        width: calc(55% - 20px);
      }
    }
  }
}

@media screen and (max-width: 768px){
  .invoice{
    padding-left: 10px;
    padding-right: 10px;
    &__title{
      font-size: 28px;
    }
  }
  .balance{
    padding-left: 0;
    padding-right: 0;
    &__title{
      font-size: 28px;
      padding-left: 10px;
      padding-right: 10px;
    }
    &__current{
      flex-direction: column;
      align-items: flex-start;
      width: calc(100% - 20px);
      margin-left: auto;
      margin-right: auto;
    }
    &__stat{
      align-items: flex-start;
      margin-top: 10px;
    }
    &__info{
      svg{
        display: none;
      }
    }
    &__check{
      border-radius: 0;
      padding-left: 10px;
      padding-right: 10px;
      flex-direction: column;
      &_left,
      &_right{
        width: 100%;
      }
      &_right{
        margin-top: 27px;
      }
    }
    &__checkout{
      width: 100%;
    }
  }
}

.coupon{
  &__error{
    color: red;
    font-size: 14px;
    margin-top: 8px;
    padding-left: 10px;
  }
}