.partners{
  padding: 10px 0 70px 0;
  &__de{
    margin-top: -45px;
  }
  &__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    a{
      img{
        width: 100%;
      }
      &:first-child{
        width: 150px;
      }
      &:nth-child(2){
        width: 179px;
      }
      &:nth-child(3){
        width: 156px;
      }
      &:nth-child(4){
        width: 192px;
      }
      &:last-child{
        width: 210px;
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .partners{
    padding-top: 0;
  }
}

@media screen and (max-width: 991px){
  .partners{
    padding-top: 20px;
    &__wrapper{
      justify-content: center;
      row-gap: 16px;
      column-gap: 30px;
    }
  }
}

@media screen and (max-width: 576px){
  .partners{
    padding-top: 0;
    &__de{
      margin-top: 0;
    }
    &__wrapper{
      justify-content: space-around;
      a{
        &:first-child{
          width: 40%;
        }
        &:nth-child(2){
          width: 40%;
        }
        &:nth-child(3){
          width: 40%;
        }
        &:nth-child(4){
          width: 40%;
        }
        &:last-child{
          width: 40%;
        }
      }
    }
  }
}