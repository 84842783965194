.verification{
  height: calc(100vh - 281px);
  min-height: 585px;
  padding: 86px 0 80px 0;
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__left{
    max-width: 652px;
    width: 100%;
  }
  &__right{
    padding-right: 90px;
  }
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin: 0;
  }
  &__descr{
    margin: 24px 0;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #2e2e2e;
    opacity: 0.8;
  }
  &__search{
    position: relative;
    display: flex;
    width: 560px;
    height: 56px;
    background: #E5EEF8;
    border: 1px solid #0091D2;
    border-radius: 10px;
    padding: 0 40px 0 16px;
    margin: 0;
    input{
      outline: none;
      width: 100%;
      height: 100%;
      color: #000000;
      font-size: 16px;
      line-height: 130%;
      border: none;
      background: transparent;
      &::-webkit-input-placeholder { 
        color: #AEAEAE;
      }
      &::-moz-placeholder {
        color: #AEAEAE;
      }
      &:-ms-input-placeholder {
        color: #AEAEAE;
      }
      &:-moz-placeholder { 
        color: #AEAEAE;
      }      
      &:active,&:focus{
        outline: none;
      }
    }
    &_loupe{
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  &__noty{
    margin-top: 16px;
    transition: .2s ease-in-out;
  }
}


@media screen and (max-width: 991px){
  .verification{
    &__title{
      font-size: 36px;
    }
    &__left{
      max-width: 452px;
    }
    &__search{
      width: 100%;
    }
    &__right{
      width: 250px;
      padding-right: 0;
      img{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .verification{
    padding-top: 20px;
    height: calc(100vh - 687px);
    min-height: 673px;
    &__wrapper{
      flex-direction: column-reverse;
      align-items: center;
    }
    &__right{
      width: 143px;
    }
    &__title{
      font-size: 32px;
      text-align: center;
    }
    &__descr{
      text-align: center;
    }
  }
}


@media screen and (max-width: 576px){
  .verification{
    height: auto;
  }
}