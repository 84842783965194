.benefits{
  padding: 91px 0 79px 0;
  &__title{
    margin: 0;
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
    span{
      color: #0091D2;
    }
  }
  &__wrapper{
    margin-top: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media screen and (max-width: 991px){
  .benefits{
    &__title{
      font-size: 38px;
    }
  }
}

@media screen and (max-width: 768px){
  .benefits{
    padding: 91px 0 53px 0;
    &__title{
      font-size: 28px;
    }
    &__wrapper{
      margin-top: 40px;
      flex-direction: column;
    }
  }
}