.registration{
  padding: 57px 0;
  &__banner{
    padding: 32px 64px 37px 69px;
    background: #0091D2;
    border-radius: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
  }
  &__descr{
    margin-top: 6px;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }
  &__image{
    position: absolute;
    bottom: 0;
    right: 284px;
    opacity: 0.7;
  }
}

@media screen and (max-width: 1200px){
  .registration{
    &__title{
      max-width: 400px;
    }
    &__descr{
      max-width: 400px;
    }
    &__image{
      right: 182px;
    }
  }
}

@media screen and (max-width: 991px){
  .registration{
    padding: 32px 39px 62px 26px;
    &__image{
      right: 6px;
    }
  }
}

@media screen and (max-width: 768px){
  .registration{
    &__banner{
      flex-direction: column;
      padding: 32px 23px 34px 23px;
    }
    > div {
      order: 1;
    }
    &__image{
      position: relative;
      order: 2;
    }
    button{
      order: 3;
      margin-top: 20px;
    }
    &__title{
      font-size: 28px;
    }
    &__descr{
      font-size: 16px;
    }
    &__image{
      width: 100%;
    }
  }
}