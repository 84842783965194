.how{
  &__content{
    position: relative;
    padding: 39px 97px 81px 0;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      background: #0091D2 url('../../../../img/stepsBg.png') no-repeat;
      background-position: right;
      border-radius: 0 20px 20px 0;
      width: 200vw;
      height: 100%;
    }
  }
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    width: 461px;
    margin-left: auto;
    position: relative;
    z-index: 2;
    span{
      color: #1C475C;
    }
  }
  &__steps{
    position: relative;
    margin-top: -32px;
    position: relative;
    z-index: 2;
    > div:nth-child(even){
      margin-left: auto;
    }
  }
}

.howStep{
  position: relative;
  background: #FFFFFF;
  border: 2px solid #E9E9E9;
  border-radius: 20px;
  padding: 40px 60px 38px 40px;
  width: 375px;
  margin-bottom: 9px;
  &:last-child{
    margin-bottom: 0;
  }
  &__descr{
    margin-top: 16px;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
    position: relative;
    z-index: 3;
  }
  &__way1{
    position: absolute;
    right: -486px;
    top: 83px;
  }
  &__way2{
    position: absolute;
    left: -339px;
    top: 114px;
  }
  &__way3{
    position: absolute;
    left: 199px;
    top: 193px;
  }
}

@media screen and (max-width: 1200px){
  .how{
    &__steps{
      margin-top: 30px;
    }
  }
  .howStep{
    // width: 510px;
    &__way1{
      right: -310px;
    }
  }
}

@media screen and (max-width: 991px){
  .how{
    &__title{
      margin-left: 0;
      font-size: 38px;
      br{
        display: none;
      }
    }
  }
  .howStep{
    width: 100%;
    &__way1,
    &__way2,
    &__way3{
      display: none;
    }
  }
}

@media screen and (max-width: 768px){
  .how{
    &__title{
      font-size: 28px;
      width: 100%;
      br{
        display: block;
      }
    }
  }
}

@media screen and (max-width: 576px){
  .how{
    &__content{
      padding-right: 19px;
    }
  }
  .howStep{
    padding: 30px;
  }
}