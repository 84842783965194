.about{
  padding: 66px 0;
  height: calc(100vh - 285px);
  min-height: 1500px;
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin: 0 0 25px 0;
  }
  &__wrapper{
    display: flex;
    align-items: flex-start;
    &:last-child{
      margin-top: 76px;
      .about__info{
        margin: 0 21px 0 0;
      }
    }
  }
  &__subtitle{
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #2E2E2E;
    span{
      color: #0091D2;
      font-weight: 600;
    }
  }
  &__text{
    margin-top: 24px;
    font-size: 20px;
    line-height: 130%;
    color: #2e2e2e;
    opacity: .8;
  }
  &__info{
    margin-left: 18px;
    &_wrapper{
      display: flex;
      justify-content: space-between;
      margin-top: 17px;
    }
  }
}


@media screen and (max-width: 1200px){
  .about{
    height: auto;
  }
}

@media screen and (max-width: 991px){
  .about{
    height: auto;
    &__wrapper{
      flex-direction: column;
      &:last-child{
        flex-direction: column-reverse;
        .about{
          &__info{
            margin: 0;
          }
        }
      }
    }
    &__mainImage{
      width: 187px;
      display: block;
      margin-left: auto;
    }
    &__info{
      margin: -50px 0 0 0;
      &_wrapper{
        margin-bottom: 24px;
        img{
          width: calc(50% - 10px);
        }
      }
    }
    &__images{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 768px){
  .about{
    padding: 40px 0;
    &__title{
      font-size: 32px;
    }
    &__subtitle{
      font-size: 28px;
    }
    &__mainImage{
      width: 187px;
      height: 194px;
      object-fit: cover;
      border-radius: 3px;
    }
  }
}

@media screen and (max-width: 350px){
  .about{
    &__mainImage{
      width: 160px;
    }
  }
}