.warning{
  padding: 102px 0 65px 0;
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__image{
    background: #1C475C;
    border-radius: 20px;
    position: relative;
    width: 530px;
    height: 319px;
    img{
      position: absolute;
      top: -25px;
      right: -32px;
    }
  }
  &__descr{
    width: calc(100% - 580px);
    font-size: 16px;
    line-height: 130%;
    color: #2E2E2E;
    &_title{
      font-weight: 600;
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 1200px){
  .warning{
    &__image{
      width: 500px;
      height: 300px;
      img{
        width: 500px;
      }
    }
    &__descr{
      width: calc(100% - 550px);
    }
  }
}

@media screen and (max-width: 991px){
  .warning{
    &__wrapper{
      flex-direction: column;
    }
    &__descr{
      width: 100%;
      margin-top: 30px;
    }
    &__image{
      margin: 0 auto;
    }
    &__descr{
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 576px){
  .warning{
    &__image{
      width: 90%;
      height: auto;
      margin-right: auto;
      margin-left: 0;
      img{
        width: 100%;
        position: relative;
      }
    }
  }
}