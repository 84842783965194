.form{
  background: #E5EEF8;
  border-radius: 20px;
  padding: 24px 40px;
  margin-left: -39px;
  &__label{
    padding-left: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #2E2E2E;
    margin-bottom: 8px;
  }
  &__wrapper{
    margin-top: 8px;
    display: flex;
    align-items: center;
  }
  &__input{
    width: 257px;
    height: 50px;
    padding: 0 16px;
    background: #FFFFFF;
    border: 1px solid #AEAEAE;
    border-radius: 10px;
    outline: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #0091D2;
  }
  &__button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 163px;
    height: 48px;
    margin-left: 10px;
    outline: none;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    border: 1px solid #0091D2;
    cursor: pointer;
    div{
      margin-left: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;
    }
    &_disable{
      opacity: .5;
      pointer-events: none;
    }
  }
  &__balance{
    margin-top: 9px;
    padding-left: 16px;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #000000;
    &_mobile{
      display: none;
    }
    span{
      color: #0091D2;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 1250px){
  .form{
    &__wrapper{
      flex-direction: column;
      align-items: flex-start;
    }
    &__button{
      margin: 10px 0 0 0;
    }
  } 
}

@media screen and (max-width: 991px){
  .form{
    &__label{
      padding-left: 0;
    }
    &__balance{
      padding-left: 0;
    }
  } 
}

@media screen and (max-width: 768px){
  .form{
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
    padding: 18px 15px 27px 18px;
    &__input{
      width: 100%;
    }
    &__button{
      width: 100%;
    }
    &__balance{
      display: none;
      &_mobile{
        display: flex;
        span{
          margin-left: 5px;
        }
      }
    }
  }
}