.modal{
  backdrop-filter: blur(4.5px);
  background: rgba(229,238,248,.3);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &__inner{
    width: 487px;
    padding: 40px 33px;
    background: #FFFFFF;
    border-radius: 10px;
  }
  &__close{
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    margin: -14px -14px 0 auto;
  }
  &__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: #0091D2;
    text-align: center;
  }
  &__noty{
    margin-top: 32px;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
  }
  &__check{
    margin-top: 16px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #0091D2;
  }
  &__paid{
    width: 222px;
    height: 43px;
    border: 1px solid #0091D2;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    cursor: pointer;
    margin: 32px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
  }
  &__closeTimer{
    width: 222px;
    height: 43px;
    border: 1px solid #0091D2;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    cursor: pointer;
    margin: 32px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #0091D2;
  }
  &__exit{
    margin: 32px auto 0 auto;
    width: 222px;
    height: 43px;
    cursor: pointer;
    outline: none;
    border: 2px solid #0091D2;
    border-radius: 3px;
    font-weight: 500;
    background: #ffffff;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #0091D2;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s ease-in-out;
  }
  &__qr{
    width: 220px;
    height: 220px;
    margin: 32px auto 0 auto;
    border: 1px solid #0091D2;
    border-radius: 4px;
    padding: 10px;
    svg{
      width: 100%;
      height: 100%;
    }
  }
}


@media screen and (max-width: 768px){
  .modal{
    &__title{
      font-size: 28px;
    }
    &__inner{
      padding: 24px 14px;
    }
  }
}

@media screen and (max-width: 576px){
  .modal{
    &__inner{
      width: calc(100% - 20px);
    }
  }
}