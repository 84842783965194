.status{
  padding: 2px 16px;
  display: inline-flex;
  justify-content: center;
  border-radius: 21px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  text-transform: capitalize;
}