.thank{
  min-height: 840px;
  padding: 30px 0 0 0;
  height: calc(100vh - 285px);
  &__wrapper{
    margin-top: 19px;
  }
  &__title{
    margin: 0;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
  }
  &__id{
    margin-top: 12px;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #2E2E2E;
  }
  &__noty{
    margin-top: 12px;
    background: rgba(234, 84, 85, 0.25);
    border-left: 4px solid #EA5455;
    padding: 12px 22px;
    display: flex;
    align-items: center;
    div{
      margin-left: 10px;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #EA5455;
    }
  }
  &__steps{
    margin-top: 44px;
    &_title{
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #000000;
    }
    &_list{
      margin-top: 12px;
      div{
        font-size: 16px;
        line-height: 130%;
        color: #000000;
        a{
          color: #0091D2;
          text-decoration: underline;
          &:hover,
          &:active,
          &:focus,
          &:visited{
            color: #0091D2;
            text-decoration: underline;
          }
        }
      }
    }
    &_noty{
      margin-top: 12px;
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #0091D2;
    }
  }
  &__left{
    width: 50%;
  }
  &__right{
    width: 45%;
  }
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1200px){
  .thank{
    min-height: 800px;
    &__right{
      img{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 991px){
  .thank{
    min-height: 1050px;
    &__wrapper{
      flex-direction: column-reverse;
    }
    &__left{
      width: 100%;
      margin-top: 30px;
    }
    &__right{
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 768px){
  .thank{
    &__title{
      font-size: 24px;
    }
    &__id{
      font-size: 20px;
    }
    &__noty{
      div{
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 576px){
  .thank{
    &__right{
      width: 172px;
    }
  }
}