.promo{
  padding: 106px 0 115px 0;
  overflow: hidden;
  &__wrapper{
    position: relative;
    display: flex;
    align-items: center;
  }
  &__info{
    background: #0091D2;
    border-radius: 20px;
    position: relative;
    width: 847px;
    height: auto;
    padding-bottom: 37px;
    &_inner{
      background: #ffffff;
      position: relative;
      top: 19px;
      left: 29px;
      border-radius: 20px;  
      width: 618px;
      height: auto;
      padding: 20px 33px 20px 20px;
      box-sizing: border-box;
      z-index: 3;
    }
  }
  &__title{
    margin: 0;
    font-weight: 700;
    font-size: 54px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #2E2E2E;
    span{
      color: #0091D2;
    }
  }
  &__descr{
    margin: 24px 0 0 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #2E2E2E;
    opacity: 0.8;
  }
  &__img{
    position: absolute;
    left: 545px;
    &_mobile{
      display: none;
    }
  }
}

@media screen and (max-width: 1200px){
  .promo{
    &__img{
      width: 600px;
      left: 478px;
    }
  }
}

@media screen and (max-width: 991px){
  .promo{
    padding: 70px 0 90px 0;
    &__title{
      font-size: 41px;
    }
    &__info{
      margin-top: 2.5em;
    }
    &__img{
      width: 512px;
      left: 338px;
      top: -6px;
    }
  }
}

@media screen and (max-width: 768px){
  .promo{
    padding-top: 20px;
    padding-bottom: 30px;
    &__wrapper{
      flex-direction: column-reverse;
    }
    &__info{
      height: auto;
      width: 100%;  
      background: transparent;
      &_inner{
        margin-top: 20px;
        position: relative;
        width: 100%;
        margin-right: 0;
        padding: 0;
        top: 0;
        left: 0;
      }
    }
    &__img{
      left: 0;
      top: 0;
      position: relative;
      width: 100%;
    }
    &__title{
      font-size: 32px;
    }
    &__descr{
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 576px){
  .promo{
    padding-bottom: 20px;
    > div {
      padding: 0;
    }
    &__info{
      &_inner{
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}