.signup{
  padding: 86px 0;
  overflow: hidden;
  height: calc(100vh - 285px);
  min-height: 685px;
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__left,
  &__right{
    width: calc(50% - 33px);
  }
  &__title{
    margin: 0;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin: 0;
  }
  &__descr{
    display: flex;
    margin-top: 24px;
    div{
      margin-right: 10px;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #25283C;
    }
    a{
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      text-decoration-line: underline;
      color: #0091D2;
    }
  }
  &__form{
    margin-top: 24px;
    label{
      margin-bottom: 10px;
      display: block;
    }
    &_wrapper{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      label{
        width: calc(50% - 8px);
        display: block;
      }
    }
  }
  &__button{
    width: 159px;
    height: 43px;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    border: 1px solid #0091D2;
    cursor: pointer;
    margin-top: 24px;
    &_disable{
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &__right{
    position: relative;
    height: 480px;
    &::after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background: #E5EEF8 url('../../img/banner.svg');
      background-position: 71px center;
      background-repeat: no-repeat;

      border-radius: 20px 0px 0px 20px;
    }
  }
  &__errors{
    margin-top: 20px;
    &_error{
      color: red;
      font-size: 14px;
      margin-bottom: 12px;
      text-transform: capitalize;
    }
  }
}


@media screen and (max-width: 991px){
  .signup{
    height: calc(100vh - 420px);
    &__title{
      font-size: 32px;
    }
    &__right{
      &::after{
        background-position: 30px center;
        background-size: 300px;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .signup{
    padding: 30px 0 108px 0;
    height: auto;
    &__wrapper{
      flex-direction: column-reverse;
    }
    &__right{
      width: 100%;
      height: 338px;
      &::after{
        width: 100%;
        border-radius: 20px;
        background-size: 85%;
        background-position: center;
      }
    }
    &__left{
      width: 100%;
      margin-top: 15px;
    }
    &__title{
      text-align: center;
    }
    &__descr{
      flex-direction: column;
      align-items: center;
      div{
        margin: 0 0 15px 0;
      }
    }
    &__form{
      &_wrapper{
        flex-direction: column;
        label{
          width: 100%;
        }
      }
    }
    &__button{
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px){
  .signup{
    &__right{
      height: 330px;
    }
  }
}

@media screen and (max-width: 450px){
  .signup{
    &__right{
      height: 260px;
    }
  }
}

@media screen and (max-width: 385px){
  .signup{
    &__right{
      height: 200px;
    }
  }
}