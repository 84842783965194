.registration{
  padding: 65px 0;
  height: calc(100vh - 285px);
  min-height: 516px;
  &__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__left{
    width: 55%;
    padding-top: 60px;
  }
  &__right{
    width: 40%;
    img{
      width: 100%;
    }
  }
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
  }
  &__link{
    margin-top: 24px;
    display: inline-flex;
    padding: 12px 32px;
    border: 1px solid #0091D2;
    background: #0091D2;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
    transition: .2s ease-in-out;
    &:hover{
      background: #fff;
      color: #0091D2;
      transition: .2s ease-in-out;
    }
  }
}


@media screen and (max-width: 991px){
  .registration{
    height: calc(100vh - 420px);
    min-height: 416px;
    &__left{
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 768px){
  .registration{
    height: calc(100vh - 752px);
    min-height: 554px;
   &__wrapper{
    flex-direction: column-reverse;
   }
   &__left,
   &__right{
    width: 100%;
   }  
   &__right{
    display: flex;
    justify-content: center;
    img{
      max-width: 243px;
      width: 100%;
    }
   }
   &__title{
    padding-top: 8px;
    font-size: 32px;
    text-align: center;
   }
   &__link{
    margin: 24px auto 0 auto;
    display: block;
   }
  }
}