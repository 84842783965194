.payment{
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .payment__checkmark{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    background-color: #ffffff;
    border: 1px solid #0091D2;
    border-radius: 50%;
    &:after {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #0091D2;
    }
  }
  &__checkmark{
    &:after{
      content: "";
      position: absolute;
      display: none;
    }
  }
  input:checked ~ .payment__checkmark:after{
    display: block;
  }
  &__value{
    display: flex;
    align-items: center;
    margin-left: 12px;
    &_block{
      width: 60px;
      min-width: 60px;
    }
    div{
      margin-left: 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      color: #1C475C;
    }
  }
}

