.label{
  position: relative;
}

.input {
  background: #ffffff;
  border: 1px solid #aeaeae;
  border-radius: 10px;
  outline: none;
  padding: 15px 52px 15px 16px;
  width: 100%;
  font-size: 16px;
  line-height: 130%;
  transition: .2s ease-in-out;
  &:focus,
  &:active{
    outline: none;
  }
  &::-webkit-input-placeholder { 
    color: #AEAEAE;
  }
  &::-moz-placeholder { 
    color: #AEAEAE;
  }
  &:-ms-input-placeholder { 
    color: #AEAEAE;
  }
  &:-moz-placeholder {
    color: #AEAEAE;
  }
  &__error{
    border-color: red!important;
    transition: .2s ease-in-out;
  }
}

.span{
  padding-left: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #2E2E2E;
  margin-bottom: 8px;
  display: block;
}

.show{
  position: absolute;
  top: 42px;
  right: 12px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.error{
  color: red;
  padding-left: 16px;
  margin-top: 4px;
  font-size: 14px;
}

@media screen and (max-width: 576px){
  .show{
    display: none;
  }
}