.certificate{
  padding: 19px 66px 86px 19px;
  width: 100%;
  &__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0091D2;
    margin: 5px 0 12px 0;
  }
  &__wrapper{
    display: flex;
    align-items: flex-start;
  }
  &__info{
    width: 100%;
  }
  &__empty{
    font-size: 18px;
  }
  &__certificate{
    min-width: 332px;
    width: 332px;
    margin-right: 39px;
    img{
      width: 100%;
    }
  }
  &__block{
    margin-bottom: 24px;
  }
  &__label{
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #1C475C;
  }
  &__value{
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
    margin-top: 4px;
  }
}

@media screen and (max-width: 1200px){
  .certificate{
    &__certificate{
      width: 280px;
      min-width: 280px;
    }
  }
}

@media screen and (max-width: 768px){
  .certificate{
    overflow: hidden;
    padding: 20px 15px 0 15px;
    &__title{
      font-size: 28px;
    }
    &__wrapper{
      flex-direction: column;
    }
    &__certificate{
      margin-right: 0;
      width: 251px;
      min-width: 251px; 
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 23px;
    }
  }
}

@media screen and (max-width: 576px){
  .certificate{
    padding-left: 10px;
    padding-right: 10px;
  }
}