.imprint{
  padding: 40px 0;
  height: calc(100vh - 281px);
  min-height: 750px;
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
  }
  &__subtitle{
    margin-top: 24px;
    color: #0091D2;
    font-size: 24px;
  }
  &__text{
    margin-top: 24px;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }
  &__link{
    color: #0091D2;
  }
}