.contact{
  height: calc(100vh - 285px);
  min-height: 928px;
  padding: 66px 0 77px 0;
  overflow: hidden;
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin: 0;
  }
  &__wrapper{
    margin-top: 41px;
    display: flex;
    justify-content: space-between;
  }
  &__info{
    width: calc(45% - 55px);
    position: relative;
    padding: 73px 60px 20px 0;
    &::after{
      content: "";
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: #E5EEF8;
      border-radius: 0px 20px 20px 0px;
    }
    &_row{
      display: flex;
      align-items: flex-start;
      position: relative;
      z-index: 3;
      margin-bottom: 30px;
      &_block{
        margin-left: 16px;
      }
      svg{
        min-width: 24px;
      }
    }
    &_label{
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #0091D2;
    }
    &_value{
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #2E2E2E;
    }
  }
  &__form{
    width: calc(55% - 55px);
    &_title{
      font-weight: 600;
      font-size: 32px;
      line-height: 130%;
      color: #0091D2;
      margin-bottom: 14px;
    }
    label{
      margin-bottom: 16px;
      display: block;
    }
  }
  &__text{
    div{
      padding-left: 16px;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: #2E2E2E;
    }
    textarea{
      resize: none;
      width: 100%;
      height: 238px;
      padding: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      outline: none;
      border: 1px solid #AEAEAE;
      border-radius: 10px;
      &::-webkit-input-placeholder { 
        color: #AEAEAE;
      }
      &::-moz-placeholder { 
        color: #AEAEAE;
      }
      &:-ms-input-placeholder { 
        color: #AEAEAE;
      }
      &:-moz-placeholder { 
        color: #AEAEAE;
      }
    }
  }
  &__button{
    width: 269px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0091D2;
    border: 1px solid #0091D2;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    opacity: 1;
    transition: .2s ease-in-out;
    svg{
      transform: rotate(180deg);
    }
    div{
      margin-right: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;
    }
    &_disabled{
      opacity: .5;
      pointer-events: none;
      transition: .2s ease-in-out;
    }
  }
  &__modal{
    width: 500px;
    padding: 20px 30px;
    background: #fff;
    border-radius: 8px;
    position: relative;
    &_wrapper{
      background: rgba(0, 0, 0, .3);
      backdrop-filter: blur(3px);
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 100vh;
    }
    &_title{
      text-align: center;
      font-weight: 600;
      font-size: 32px;
      line-height: 130%;
      color: #0091D2;
      margin-bottom: 14px;
    }
    &_image{
      width: 50px;
      margin: 0 auto;
      display: block;
    }
    button{
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      border: none;
      background: none;
    }
  }
}

@media screen and (max-width: 991px){
  .contact{
    height: calc(100vh - 420px);
    &__info{
      width: calc(45% - 30px);
    }
    &__form{
      width: calc(55% - 30px);
      &_title{
        font-size: 28px;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .contact{
    height: auto;
    padding-top: 40px;
    &__title{
      font-size: 32px;
    }
    &__wrapper{
      flex-direction: column;
      margin-top: 20px;
    }
    &__info{
      width: 100%;
      padding-top: 36px;
      padding-bottom: 25px;
    }
    &__form{
      margin-top: 39px;
      width: 100%;
    }
    &__button{
      width: 100%;
    }
  }
}