.register{
  padding: 61px 0 126px 0;
  background: #E5EEF8;
  height: calc(100vh - 285px);
  min-height: 605px;
  &__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &__block{
    width: 100%;
    box-shadow: 0px 30px 35px 25px rgba(110, 189, 255, 0.1);
    border-radius: 4px;
    padding: 85px 35px 84px 97px;
    background: #FFFFFF url('../../img/registred.svg');
    background-repeat: no-repeat;
    background-position: right;
    &_icon{ 
      display: block;
    }
  }
  &__title{
    margin: 24px 0;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #30A03B;
  }
  &__left{
    width: 70%;
  }
}


@media screen and (max-width: 1200px){
  .register{
    &__block{
      padding: 85px 35px 84px 50px;
    }
  }
}

@media screen and (max-width: 991px){
  .register{
    height: calc(100vh - 420px);
    &__block{
      background: #fff;
    }
    &__left{
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px){
  .register{
    height: calc(100vh - 752px);
    min-height: 502px;
    &__block{
      padding: 43px 35px 47px 50px;
    }
    &__title{
      font-size: 32px;
    }
    &__left{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &__title{
      text-align: center;
    }
  }
}

@media screen and (max-width: 576px){
  .register{
    &__block{
      padding: 43px 31px 47px 31px;
    }
  }
}