.package{
  margin: 0;
  border: 1px solid #A0DAF4;
  border-radius: 10px;
  width: 100%;
  padding: 20px 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ .package__checkmark:after {
    display: block;
  }
  &__active{
    background: #A0DAF4;
    transition: .1s ease-in-out;
    .package__value,
    .package__price{
      font-weight: 600;
      transition: .2s ease-in-out;
    }
  }
  &__wrapper{
    display: flex;
    align-items: center;
  }
  &__value{
    margin-left: 12px;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
  }
  &__price{
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    letter-spacing: -0.02em;
    color: #2E2E2E;
  }
  &__checkmark{
    height: 12px;
    width: 12px;
    background: #FFFFFF;
    border: 1px solid #63ADDC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after{
      content: "";
      display: none;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #0091D2;
    }
  }
}

@media screen and (max-width: 1200px){
  .package{
    padding-left: 22px;
    padding-right: 22px;
    &__value{
      font-size: 20px;
    }
    &__price{
      font-size: 20px;
    }
  }
}
