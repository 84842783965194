.news{
  background: #0091D2;
  padding: 13px 0 37px 0;
  &__wrapper{
    display: flex;
    align-items: flex-start;
  }
  &__container{
    max-width: 1140px;
    margin: 16px auto 0 auto;
  }
  &__title{
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #FFFFFF;
  }
  &__image{
    width: 173px;
    min-width: 173px;
    height: 77px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
    img{
      width: 100%;
      object-fit: cover;
    }
  }
  &__descr{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 7px;
  }
  &__readMore{
    display: flex;
    align-items: center;
    div{
      font-weight: 600;
      font-size: 13px;
      line-height: 130%;
      color: #FFFFFF;
    }
    img{
      margin-left: 5px;
    }
  }
  &__block{
    &_title{
      color: #FFFFFF;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }
  &__more{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__data{
    font-size: 13px;
    color: #FFFFFF;
    font-weight: 600;
  }
}

@media screen and (max-width: 1200px){
  .news{
    &__container{
      max-width: 960px;
    }
  }
}

@media screen and (max-width: 991px){
  .news{
    &__image{
      width: 120px;
      min-width: 120px;
      height: 59px;
    }
    &__container{
      max-width: 100%;
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 576px){
  .news{
    &__wrapper{
      flex-direction: column;
      padding: 0 45px;
    }
    &__title{
      display: none;
    }
    &__descr{
      margin-top: 10px;
    }
  }
}