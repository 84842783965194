.news{
  height: calc(100vh - 281px);
  padding: 40px 0;
  min-height: 600px;
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin: 0 0 25px 0;
  }
  &__subtitle{
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin: 0 0 25px 0;
  }
  &__descr{
    margin-top: 24px;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }
}

@media screen and (max-width: 768px){
  .news{
    &__title{
      font-size: 36px;
    }
  }
}