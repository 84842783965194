.check{
  height: calc(100vh - 285px);
  min-height: 560px;
  padding: 68px 0;
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
  }
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__left{
    width: 55%;
    padding-top: 60px;
  }
  &__right{
    width: 40%;
    img{
      width: 100%;
    }
  }
}


@media screen and (max-width: 991px){
  .check{
    height: calc(100vh - 420px);
    min-height: 520px;
    &__wrapper{
      flex-direction: column-reverse;
      align-items: center;
    }
    &__title{
      font-size: 32px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px){
  .check{
    height: calc(100vh - 752px);
    &__right{
      width: 275px;
    }
    &__left{
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px){
  .check{
    height: calc(100vh - 717px);
  }
}