.canceled{
  height: calc(100vh - 285px);
  min-height: 630px;
  padding: 80px 0;
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__left{
    width: 50%;
    padding-top: 70px;
  }
  &__right{
    width: 45%;
    img{
      width: 100%;
    }
  }
  &__title{
    font-size: 21px;
    font-weight: 500;
  }
  &__link{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    background: #0091D2;
    border: 1px solid #0091D2;;
    padding: 14px 0;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
  }
}

@media screen and (max-width: 768px){
  .canceled{
    &__wrapper{
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    &__left{
      width: 100%;
    }
    &__title{
      font-size: 18px;
    }
  }
}