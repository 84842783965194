.canceled{
  height: calc(100vh - 285px);
  min-height: 567px;
  padding: 19px 0;
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 31px;
  }
  &__left{
    width: 50%;
    padding-top: 60px;
  }
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #E47B00;
    margin: 0;
  }
  &__descr{
    margin-top: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #2E2E2E;
  }
}


@media screen and (max-width: 991px){
  .canceled{
    height: calc(100vh - 420px);
    min-height: 419px;
    &__right{
      width: 45%;
      img{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .canceled{
    min-height: 603px;
    &__wrapper{
      flex-direction: column-reverse;
      align-items: center;
    }
    &__left{
      width: 100%;
    }
    &__title{
      font-size: 24px;
    }
    &__descr{
      font-size: 20px;
    }
    &__right{
      width: 400px;
    }
  }
}


@media screen and (max-width: 576px){
  .canceled{
    min-height: 480px;
    &__right{
      width: 215px;
    }
  }
}