.error{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  height: calc(100vh - 285px);
  min-height: 500px;
  &__title{
    margin: 34px 0 24px 0;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #0091D2;
  }
  &__btn{
    padding: 12px 24px;
    border: 1px solid #0091D2;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    transition: .2s ease-in-out;
    &:hover{
      background: #fff;
      color: #0091D2;
      transition: .2s ease-in-out;
    }
  }
}


@media screen and (max-width: 991px){
  .error{
    height: calc(100vh - 420px);
  }
}


@media screen and (max-width: 991px){
  .error{
    height: calc(100vh - 752px);
    &__title{
      font-size: 32px;
    }
  }
}


@media screen and (max-width: 576px){
  .error{
    img{
      width: 280px;
    }
    height: calc(100vh - 717px);
  }
}