.select{
  &__label{
    padding-left: 16px;
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #2E2E2E;
  }
}

.dropdown{
  color: #0091D2;
}

.placeholder{
  color: #0091D2;
}