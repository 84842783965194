.modal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  height: 100vh;
  background: rgba(229, 238, 248, 0.3);
  backdrop-filter: blur(4.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  &__inner{
    width: 487px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 36px 33px 74px 33px;
  }
  &__close{
    cursor: pointer;
    background: none;
    border: none;
    display: flex;
    margin-left: auto;
    padding: 0;
    outline: none;
    margin-top: -14px;
    margin-right: -14px;
  }
  &__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: #0091D2;
    text-align: center;
  }
  &__descr{
    text-align: center;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #000000;
    max-width: 340px;
    margin: 16px auto 0 auto;
    div{
      color: #0091D2;
      font-weight: 500;
    }
  }
  &__back{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px auto 0 auto;
    width: 232px;
    padding: 14px 0;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    border: 1px solid #0091D2;
    cursor: pointer;
    text-decoration: none;
    transition: .2s ease-in-out;
    div{
      margin-left: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;
      transition: .2s ease-in-out;
    }
  }
}


@media screen and (max-width: 768px){
  .modal{
    &__inner{
      padding: 24px 17px 33px 15px;
    }
    &__close{
      margin-right: -7px;
    }
    &__title{
      font-size: 21px;
    }
    &__descr{
      font-size: 18px;
    }
    &__button{
      margin: 20px auto 0 auto;
    }
  }
}

@media screen and (max-width: 576px){
  .modal{
    &__inner{
      width: calc(100% - 20px);
    }
  }
}