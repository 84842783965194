@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.timer{
  width: 177px;
  height: 177px;
  margin: 16px auto 0 auto;
  position: relative;
  *{
    font-family: 'Bebas Neue', cursive;
    font-size: 35px!important;
  }
  svg{
    position: relative;
    z-index: 3;
  }
  &__inner{
    font-weight: 700;
  }
  &::before{
    content: '';
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #E5EEF8;
  }
}